import React, { useState, Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import '../css/colors.css';
import '../css/global.css';
import '../css/utilities.css';
import Header from '../components/header.js';
import Footer from '../components/footer.js';
import MenuData from '../data/giovannis-pizzeria-menu.json';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function getSpacer() {
	return  <div class="p-2">&nbsp;</div>
}

class MenuPage extends Component {
	constructor(props) {
        super(props);
        this.state = {
            rotations: [],
        };
	}

	toggleRotation(i) {
		// Make a shallow copy of the items
		let rotations = [...this.state.rotations];
        let toggleState = !this.state.rotations[i];
        
		// Set initial rotate state if not already exists
		if(toggleState === undefined) {
			toggleState = true;
        }
        
        rotations[i] = toggleState;
        
		// Set the state to our new copy
		this.setState({rotations});
    };
	
	getDineInMenu() {
		return (
			<div>
				{
					MenuData.Categories.map((Category, i) => {
						return (
							<div>
								<div>
									<div class="pt-2" onClick={() => this.toggleRotation(i)}>
										<div class="p-4 cursor-pointer gios-border w-100" data-toggle="collapse" data-target={"#collapse" + Category.Name.replace(/[~%&\\;:"',/<>?#\s]/g,"")} aria-expanded="false" aria-controls={"collapse" + Category.Name.replace(/[~%&\\;:"',/<>?#\s]/g,"")}>
											<div>
												<div class="d-flex justify-content-between">
													<div>
														<div class="h2 font-weight-bolder m-0">{Category.Name}</div>
													</div>
													<div class="mt-2">
														<FontAwesomeIcon icon={faChevronDown} size="lg" style={{transform: this.state.rotations[i] ? 'rotate(0deg)' : 'rotate(-90deg)', transitionDuration: ".5s" }}/>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div class="collapse" id={"collapse" + Category.Name.replace(/[~%&\\;:"',/<>?#\s]/g,"")}>
											<div class="bg-white txt-black p-4">
												{
													Category.Items.map((Item, i) => {
														return (
															<div>
																<div class="d-flex justify-content-between">
																	<div>
																		<div class="pr-5">
																			<div class="font-weight-bold">
																				{(() => {
																					if (Item.Quantity > 0) {
																						return Item.Name + " (" + Item.Quantity + ")"
																					} else {
																						return Item.Name
																					}
																				})()}
																			</div>
																			<div>{Item.Description}</div>
																		</div>
																	</div>
																	<div>
																		{
																			Object.keys(Item.Price).map((key, i) => {
																				return (
																					<div>
																						<div class="d-flex flex-row justify-content-between">
																							<div class="pr-3">
																								{(() => {
																									switch (key) {
																										case "None":  return "";
																										default:      return key;
																									}
																								})()}
																							</div>
																							<div class="font-weight-bold">
																								<NumberFormat value={Item.Price[key]} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2}/>
																							</div>																				
																						</div>	
																					</div>																					
																				);
																			})
																		}	
																	</div>
																</div>
                                                                {(() => {
                                                                    if (i < (Category.Items.length - 1)) {
                                                                        return <hr/>
                                                                    }
                                                                })()}
															</div>
														);
													})
												}	
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})
				}	
			</div>
		);
	}
	
	getCateringMenu() {
		return (
			<div>
				<div class="pt-2">
					<div class="p-4 cursor-pointer gios-border w-100" data-toggle="collapse" data-target={"#collapseTODO"} aria-expanded="false" aria-controls={"collapseTODO"}>
						<div>
							<div class="d-flex justify-content-between">
								<div>
									<div class="h4 font-weight-bolder">Coming Soon...</div>
								</div>
								<div class="mt-2">
									<FontAwesomeIcon icon={faChevronDown} size="lg" style={{transform: false ? 'rotate(0deg)' : 'rotate(-90deg)', transitionDuration: ".5s" }}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

    render() {
        return (
			<div>
				<div class="mx-auto w-100 w-lg-75 px-4 px-lg-0">

					<div class="d-flex flex-row">
						<div>
							<div class="h1 p-0 m-0">Dine-In</div>
						</div>
						{/* HIDE UNTIL NEW MENU IS READY */}
						{/* <div>
							<a href={window.location.origin + "/docs/Gios_DineInMenu_2022.pdf"} target="_blank" class="nav-link p-0 m-0">
								<div class="btn btn-danger mt-2 ml-2">View PDF</div>
							</a>
						</div> */}
					</div>

					{this.getDineInMenu()}
					{getSpacer()}

					<div class="d-flex flex-row">
						<div>
							<div class="h1 p-0 m-0">Take-Out</div>
						</div>
						<div>
							<a href={window.location.origin + "/docs/Gios_TakeOutMenu_2022_v2.pdf"} target="_blank" class="nav-link p-0 m-0">
								<div class="btn btn-danger mt-2 ml-2">View PDF</div>
							</a>
						</div>
					</div>

					<div class="d-flex flex-row">
						<div>
							<div class="h1 p-0 m-0">Catering</div>
						</div>
						<div>
							<a href={window.location.origin + "/docs/Gios_CateringMenu_2022_v2.pdf"} target="_blank" class="nav-link p-0 m-0">
								<div class="btn btn-danger mt-2 ml-2">View PDF</div>
							</a>
						</div>
					</div>

					{/* {this.getCateringMenu()} */}
					{getSpacer()}

				</div>
			</div>
        );
    }
}

export default MenuPage;
